:root {
    --white: #ffffff;
    --custom-gray: #a8a8a9;
    --custom-beige: #f2eeeb;
    --custom-green: #3C592E;
    --custom-light-green: #168039;
    --custom-neon-green: #00BA32;
    --text-color-green: #687469;
    --custom-yellow-base: #F1CF6D;
    --header-primary: #334b35;
    --custom-mid-green: #f2f5e8;
}

/* Global Styles */
body {
    font-weight: 400;
    font-size: 1em;
    line-height: 1.2;
    /* word-break: break-all; */
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    padding-top: 4em;
}

a,
a:hover {
    color: var(--dark);
}

a {
    text-decoration: none;
}


h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
    font-family: "Lexend", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
}

h1,
.h1 {
    font-weight: 800;
}

h2,
.h2 {
    font-weight: 700;
}

h3,
.h3 {
    font-weight: 600;
}

h4,
h5,
.h4,
.h5 {
    font-weight: 500;
}


h2,
h3,
h4,
h5,
h6 {
    color: var(--custom-green);
}

h3 {
    color: var(--custom-light-green);
}

p {
    line-height: 2;
}

.bg-beige {
    background-color: var(--custom-beige);
}

.social-links .list-group-item a {
    width: 30px;
    height: 30px;
    line-height: 28px;
    display: block;
    border-radius: 50%;
    border: 2px solid var(--custom-gray);
    color: var(--custom-gray);
    text-align: center;
    opacity: 1;
}

.social-links .list-group-item a:hover {
    -webkit-filter: invert(1);
    filter: invert(1);
}

.vendor-img-wrapper {
    border: 1px solid var(--custom-light-green);
    padding: .75rem;
    border-radius: 50%;
    position: relative;
    display: inline-block;
}

.vendor-img-wrapper .vendor-img {
    display: block;
    border-radius: 50%;
    height: 200px;
    width: 200px;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: var(--custom-gray);
}

.grandpafarm-img {
    background-image: url("../img/vendors/grandpafarm.jpg");
}

.thehouseofgreens-img {
    background-image: url("../img/vendors/thehouseofgreens.jpg");
}

.perexmarket-img {
    background-image: url("../img/vendors/perexmarket.jpg");
}

.miohmy-img {
    background-image: url("../img/vendors/miohmy.jpg");
}

/* 
.liberation-img {
    background-image: url("../img/vendors/liberationfarm.jpg");
} */


/* Logo */
.logo {
    min-width: 30px;
}

/* NAVBAR */

.navbar {
    background-color: var(--custom-beige);
}

.navbar .cart-items {
    cursor: pointer;
}

nav .dropdown-item.active,
nav .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: var(--custom-green);
}

/* HOME PAGE*/
.main-home {
    padding-top: 3rem;
    background-color: var(--custom-beige);
}

.main-home .card .card-title,
.info-container .title,
.hero-hed {
    z-index: 8;
    white-space: wrap;
    font-size: 3.25em;
    font-weight: 400;
    color: rgb(10, 10, 10);
    letter-spacing: 0px;
}

.info-wrapper .col-item {
    background-color: var(--white);
    margin-top: 5rem;
    padding-bottom: 2rem;
}

.info-wrapper .badge {
    margin-top: -5rem;
}

.bg-banner {
    background-color: var(--custom-yellow-base);
}

.bg-banner-natural {
    background-color: #f5f3eb;
}

.boxes {
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.boxes .section {
    background-color: var(--white);
    padding: 1rem;
    border-radius: 1rem;
}

.boxes .section>div {
    display: grid;
    grid-template-columns: .8fr 1fr;
    grid-gap: 1em;
    align-items: center;
    margin: 0;
}

.boxes .section>div .icon {
    color: var(--custom-yellow-base);
    text-align: center;
}

.boxes .section>div {
    color: #334b35;
}

/* Feature Cards */
.feature-container .card {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding: 12% 3%;
}

.feature-container .card .card-body {
    display: flex;
    flex-flow: column;
}

.feature-container .card .card-title {
    font-weight: 600;
}

.feature-merchandise {
    background-image: linear-gradient(180deg, rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url('../img/features/t-shirt-2.png');
}

.feature-nutrition {
    background-image: linear-gradient(180deg, rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url('../img/features/nutrition-banner.jpg');
}

.feature-farm-stand {
    background-image: linear-gradient(180deg, rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url('../img/features/uptowngoodfood.jpg');
}

.feature-fm-donate {
    background-image: linear-gradient(180deg, rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url('../img/features/community_supporter.jpg');
}

/* Jumbotron */

.jumbotron {
    padding: 2rem 1rem;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, .3), rgba(0, 0, 0, .3)), url('../img/jumbotron/green-peppers.jpg');
    background-size: cover;
    margin-bottom: 2vw;
    margin-top: 1rem;
    border-radius: 0 !important;
}

.jumbotron .hero-hed {
    color: var(--white);
}


.jumbotron.csa-info-jumbotron {
    background-image: none;
    background-color: var(--custom-beige);
}

/* BANNER */
.banner-container {
    min-height: 250px;
    color: var(--white);
}

.banner-image-container {
    background: 50% no-repeat #000;
    background-size: cover;
}

@media (min-width: 992px) {
    .banner-container {
        background-attachment: fixed;
        min-height: 405px;
    }

}

.about-banner-container {
    background-image: linear-gradient(180deg, rgba(0, 0, 0, .4), rgba(0, 0, 0, .4)), url('../img/banner/blue-berries.png');
}

.farmers-market-banner-container {
    background-image: linear-gradient(180deg, rgba(0, 0, 0, .4), rgba(0, 0, 0, .4)), url('../img/banner/farmers-table.png');
}

.kids-banner-container {
    background-image: linear-gradient(180deg, rgba(0, 0, 0, .4), rgba(0, 0, 0, .4)), url('../img/banner/kids-art.jpg');
}

.waste-banner-container {
    background-image: linear-gradient(180deg, rgba(0, 0, 0, .4), rgba(0, 0, 0, .4)), url('../img/banner/compost-dropoff.jpg');
}

.default-banner-container {
    background-image: linear-gradient(180deg, rgba(0, 0, 0, .4), rgba(0, 0, 0, .4)), url('../img/banner/mix-green-peppers.jpg');
}

.recipes-boxes .recipe-section:nth-child(even) {
    background: var(--custom-mid-green)
}

.recipes-boxes h4 {
    padding-top: 2rem;
}

/* REUSABLE BAG */

.reusable-bag {
    background-color: #F7F0E9;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../img/reusable-bag.jpg");
    /* background-image: linear-gradient(180deg, rgba(150, 194, 147, 0.6), rgba(176, 216, 175, .1)), url(./images/salad-bowl.png); */
    background-position: top right;
}

/* FOOTER */
footer {
    color: #0f0f10;
}

footer .col-title {
    font-weight: 700;
    margin-bottom: 0.5em;
}

footer ul>li {
    line-height: 1.5em;
}

#cooking-demo {
    max-width: 432px;
}


/* Media queries  */

/* X-Small devices (portrait phones, less than 576px) */
@media (max-width: 576px) {

    .display-3,
    .display-4 {
        font-size: 2.0rem;
    }
}

@media (min-width: 576px) {
    .jumbotron {
        padding: 3rem 2rem;
    }
}

@media only screen and (max-width: 580px) {
    .reusable-bag {
        background-position: center center;
    }

    .local-restaurants-chefs-container {
        flex-direction: column-reverse;
    }
}